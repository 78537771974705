import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
const useStyles = makeStyles({
    root: {
        backgroundColor: "#fff"
    }
})
export default function PersistentDialog({ open, setOpen, loading, setTimer }) {
    const classes = useStyles()
    const handleClose = () => {
        setOpen(false);
    };
    const test = useSelector(state => state.test)
    function time_convert(num) {
        var hours = Math.floor(num / 60);
        var minutes = num % 60;
        let smins = minutes === 0 ? "" : ` ${minutes} minutes`
        return `0${hours} hours` + smins;
    }
    return (
        loading ?
            <Box p={2}>
                <CircularProgress />
            </Box>
            :
            test.test_data?.time_limit ?
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    BackdropProps={{
                        classes: classes
                    }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"This assignment has time limit."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`The time to finish this assignment is ${time_convert(test.test_data.time_limit)}`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setTimer(test.test_data.time_limit)} color="primary">Start</Button>
                    </DialogActions>

                </Dialog>
                :
                null
    );
}
