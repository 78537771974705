import { Box, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Table, TableContainer, TableCell, TableRow, TableHead, TableBody, makeStyles } from "@material-ui/core";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";
import React from "react"
import { MathfieldComponent } from "react-mathlive";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    bgColor: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
}))

const QuestionViewer = ({ questions, data, handleChange, handleMathChange, setFile, submit }) => {
    const classes = useStyles()
    const history = useHistory()
    let questionNumber = history.location.hash ? parseInt(history.location.hash.replace("#", '')) : 1
    let question = questions[questionNumber - 1]
    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    return (
        <Grid container direction="row" spacing={4}>
            <Grid item xs={2}>
                <Button onClick={() => history.replace("/")} startIcon={<ChevronLeftRounded />} variant="contained" color='primary' fullWidth>Exit</Button>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="questions">
                        <TableHead>
                            <TableRow>
                                <TableCell>Question</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map((row, index) => (
                                <TableRow key={row.id} className={questionNumber === index + 1 ? classes.bgColor : null}>
                                    <TableCell component="th" scope="row" style={{ color: 'inherit' }}>
                                        <a href={`#${index + 1}`} style={{ color: "inherit", textDecoration: 'none' }} onClick={() => {
                                            window.scrollTo(0, 0)
                                        }}>{`Q${index + 1}`}</a>
                                    </TableCell>
                                    <TableCell style={{ color: 'inherit' }} align="right">{data[row.id] ? <i>Answered</i> : null}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
            {question ?
                <Grid item xs={8} style={{ userSelect: "none" }}>
                    <Grid item key={question.id}>
                        {question.intro_text != null && <div className="m-3 " dangerouslySetInnerHTML={{ __html: question.intro_text }}></div>}
                        {question.intro_image != null && <div><img className="m-3 " src={window.location.origin + "/" + question.intro_image} alt={`intro_image${question.id}`} style={{ maxWidth: "100%" }} /></div>}
                        <br></br>
                        <Card raised>
                            <CardHeader title={`Q${questionNumber}`}>
                            </CardHeader>
                            <CardContent>
                                {question.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: question.question }}></div>}
                                {question.question_image != null && <div><img src={window.location.origin + "/" + question.question_image} alt={`question_image${question.id}`} style={{ maxWidth: "100%" }} /></div>}
                                <br />
                                {question.mcq === 1 ?
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={question.id + ""} name={String(question.id)} value={data[question.id]} onChange={handleChange}>
                                            {JSON.parse(question.options).map((option, index) => (
                                                option.type === "string" ?
                                                    <FormControlLabel
                                                        key={`Option${question.id}${index + 1}`}
                                                        value={String(index + 1)}
                                                        control={<Radio />}
                                                        label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                                    />
                                                    :
                                                    <FormControlLabel
                                                        key={`Option${question.id}${index + 1}`}
                                                        value={String(index + 1)}
                                                        control={<Radio />}
                                                        label={
                                                            <Grid container direction="row" alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <div>{alphabets[index]}</div>
                                                                </Grid>
                                                                <Grid item>
                                                                    <div><img src={window.location.origin + "/" + option.value} alt={`Option${index + 1}`} style={{ maxWidth: "100%" }} /></div>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    :
                                    question.mcq === 2 ?
                                        <div style={{ border: "1px dashed black", width: "25%", padding: 8 }} key={`M${question.id}`}>
                                            <input
                                                type="file"
                                                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                onChange={e => {
                                                    setFile({
                                                        question_id: question.id,
                                                        file: e.target.files[0]
                                                    })
                                                }} />
                                        </div>
                                        :
                                        <div style={{ border: "1px solid black", width: "25%" }} key={`M${question.id}`}>
                                            <MathfieldComponent
                                                // latex={latex}
                                                onChange={(value) => handleMathChange(question.id, value)}
                                                mathfieldConfig={{
                                                    virtualKeyboardMode: "onfocus",
                                                    virtualKeyboardTheme: "material",
                                                    editingOptions: {
                                                        ignoreSpacebarInMathMode: true,
                                                    }
                                                }}
                                            />
                                        </div>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box display={'flex'} justifyContent='space-between' marginTop={2}>
                        {questionNumber > 1 ?
                            <Button variant="contained" startIcon={<ChevronLeftRounded />} color="primary" href={`#${questionNumber - 1}`}>
                                Previous
                            </Button>
                            :
                            <div></div>}
                        <Button variant="contained" endIcon={<ChevronRightRounded />} color="primary" href={`#${questionNumber + 1}`}>
                            Next
                        </Button>
                    </Box>
                </Grid>
                :
                questions.length > 0 ?
                    <Grid item xs={8}>
                        <Card raised>
                            <CardHeader title={"Congratulations!"} />
                            <CardContent>
                                <p>You have reached the end of this section.</p>
                                <p>You can review the questions using the navigation menu on the left side of the screen</p>
                                <p>Click Submit Button below to close this section</p>
                                <Button variant="contained" color="primary" onClick={submit}>Submit</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    : null
            }
        </Grid>
    )
}
export default QuestionViewer