import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { sendReport } from './Redux/reportSlice'
import { enqueue } from './Redux/notifySlice'
import LinearProgress from '@material-ui/core/LinearProgress'
function BugReport() {
    const [description, setDescription] = useState("")
    const [files, setFiles] = useState([])
    const report = useSelector(state => state.report)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const submitReport = () => {
        if (description.trim() === "") {
            dispatch(enqueue(
                {
                    message: "Please describe the error/bug you are facing",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                }
            ))
            return
        }
        let formData = new FormData()
        formData.append('description',description)
        if(files.length>0){
            for(let i = 0; i<files.length; i++){
                formData.append(`images[${i}]`,files[i])
            }
        }
        let data = {
            token: auth.token,
            body: formData
        }
        dispatch(sendReport(data))
        setDescription("")
        setFiles([])
    }
    return (
        <Paper elevation={2}>
            {report.loading?<LinearProgress/>:null}
            <Box padding={2}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="h6">Report Errors</Typography>
                        <Typography variant="caption">Here you can report any issues with assignments or submissions.</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Description"
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                            fullWidth />
                    </Grid>
                    <Grid item>
                        <input label="Select Images" type="file" accept="image/*" multiple onChange={event => {
                            let f = [];
                            Array.from(event.target.files).forEach(x=>f.push(x))
                            setFiles(f)
                        }} />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={submitReport}>
                            Report
                </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default BugReport
