import Grid from '@material-ui/core/Grid'
import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import AccountCircle from '@material-ui/icons/AccountCircleRounded';
import Button from '@material-ui/core/Button';
import Compress from 'compress.js'
import { updateUser } from './Redux/authSlice';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { IMAGE_URL } from '../utils/constants';
const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    avatarText: {
        backgroundColor: "#000",
        width: theme.spacing(20),
        height: theme.spacing(20),
    }
}));
function Settings() {
    const classes = useStyles()
    const auth = useSelector(state => state.auth)
    const [image, setImage] = useState()
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({
        password: {
            error: false,
            msg: ""
        },
        password_confirmation: {
            error: false,
            msg: ""
        }
    })
    const dispatch = useDispatch()
    let compress = new Compress()

    useEffect(() => {
        compress.attach('#file', {
            size: 4,
            quality: .75
        }).then((results) => {
            // Example mimes:
            // image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon,  image/svg+xml, image/webp, image/xxx, image/png, image/jpeg, image/webp
            // If mime is not provided, it will default to image/jpeg
            const img1 = results[0]
            const base64str = img1.data
            const imgExt = img1.ext
            const file = Compress.convertBase64ToFile(base64str, imgExt)
            setImage(file)
        })
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        compress.attach('#file', {
            size: 4,
            quality: .75
        }).then((results) => {
            // Example mimes:
            // image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon,  image/svg+xml, image/webp, image/xxx, image/png, image/jpeg, image/webp
            // If mime is not provided, it will default to image/jpeg
            const img1 = results[0]
            const base64str = img1.data
            const imgExt = img1.ext
            const file = Compress.convertBase64ToFile(base64str, imgExt)
            setImage(file)
        })
        //eslint-disable-next-line
    }, [image])
    const uploadImage = () => {
        let data = new FormData()
        data.append('image', image)
        dispatch(updateUser({
            token: auth.token,
            contentType: "multipart/form-data",
            body: data
        }))
    }
    const setError = (name, msg) => {
        let newErrors = {}
        for (const field in errors) {
            newErrors[field] = {
                error: false,
                msg: ""
            }
        }
        let newObj = {
            error: true,
            msg: msg
        }
        setErrors({ ...newErrors, [name]: newObj })
    }
    const changePassword = () => {
        if (!data.password || data.password.trim() === "") {
            setError("password", "Please enter password")
            return
        }
        if (data.password !== data.password_confirmation) {
            setError("password_confirmation", "Confirm password does not match")
            return
        }
        dispatch(updateUser({
            token: auth.token,
            body: {
                password: data.password,
                password_confirmation: data.password_confirmation
            }
        }))
    }
    const handleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Paper>
                    <Box p={2}>
                        <Grid container spacing={2} direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                {auth.user.image ?
                                    <Avatar alt={auth.user.name} src={IMAGE_URL + auth.user.image} className={classes.large} />
                                    :
                                    <Avatar className={classes.avatarText}><AccountCircle className={classes.avatarText} /></Avatar>
                                }
                            </Grid>
                            <Grid item>
                                <Grid item>
                                    <input id="file" type="file" placeholder="Select Image" accept="image/*" />
                                </Grid>
                                <Grid item>
                                    <Box mt={2}>
                                        <Button variant="contained" color="primary" onClick={uploadImage}>Upload</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <Grid container direction="column" item xs={4}>
                <Paper>
                    <Box p={1.7}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography>Change Password</Typography>
                            </Grid>
                            <Grid item>
                                <TextField label="Password" type="password" name="password" variant="outlined" fullWidth size="small" onChange={handleChange} helperText={errors.password.msg} error={errors.password.error} />
                            </Grid>
                            <Grid item>
                                <TextField label="Confirm Password" type="password" name="password_confirmation" variant="outlined" fullWidth size="small" onChange={handleChange} helperText={errors.password_confirmation.msg} error={errors.password_confirmation.error} />
                            </Grid>
                            <Grid container item justify="center">
                                <Button variant="contained" color="primary" onClick={changePassword}>Change</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Settings
