import { Box, Button, Card, CardContent, CardHeader, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Table, TableContainer, TableCell, TableRow, TableHead, TableBody, makeStyles } from "@material-ui/core";
import { ChevronLeftRounded, ChevronRightRounded, CheckCircleOutline, RadioButtonUnchecked, Cancel, Check, Clear } from "@material-ui/icons";
import React from "react"
import { MathfieldComponent } from "react-mathlive";
import { useHistory } from "react-router-dom";
import { IMAGE_URL } from "../../utils/constants";
const useStyles = makeStyles(theme => ({
    bgColor: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
}))
const SubmissionViewer = ({ questions, answers, score }) => {
    const classes = useStyles()
    const history = useHistory()
    let questionNumber = history.location.hash ? parseInt(history.location.hash.replace("#", '')) : 1
    let question = questions[questionNumber - 1]
    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    let c = ""
    if (question && question.answer === answers[question.id]) {
        c = "correct"
    } else {
        if (question && answers[question.id]) {
            c = "incorrect"
        } else {
            c = "noanswer"
        }
    }
    return (
        <Grid container direction="row" spacing={4}>
            <Grid item xs={2}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="questions">
                        <TableHead>
                            <TableRow className={classes.bgColor}>
                                <TableCell style={{color:"inherit"}}>Score</TableCell>
                                <TableCell style={{color:"inherit"}} align="right">{score}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Question</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map((row, index) => (
                                <TableRow key={row.id} className={questionNumber === index + 1 ? classes.bgColor : null}>
                                    <TableCell component="th" scope="row" style={{ color: 'inherit' }}>
                                        <a href={`#${index + 1}`} style={{ color: "inherit", textDecoration: 'none' }} onClick={() => {
                                            window.scrollTo(0, 0)
                                        }}>{`Q${index + 1}`}</a>
                                    </TableCell>
                                    <TableCell align="right">{row.answer === answers[row.id] ? <Check htmlColor="#4caf50" /> : <Clear color="error" />}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
            {question ?
                <Grid item xs={8} style={{ userSelect: "none",marginTop:"16px" }}>
                    <Grid item>
                        {question.intro_text != null && <div dangerouslySetInnerHTML={{ __html: question.intro_text }}></div>}
                        {question.intro_image != null && <img src={window.location.origin + "/" + question.intro_image} alt={`intro_image${question.id}`} />}
                        <br></br>
                        <Card raised>
                            <CardHeader title={`Q${questionNumber}`} />
                            <CardContent>
                                {c === "noanswer" && <p>(No Answer)</p>}
                                {question.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: question.question }}></div>}
                                {question.question_image != null && <img src={window.location.origin + "/" + question.question_image} alt={`question_image${question.id}`} />}
                                <br />
                                {question.mcq === 1 ?
                                    <List>
                                        {JSON.parse(question.options).map((option, index) => (
                                            option.type === "string" ?
                                                <Box maxWidth="50%">
                                                    {/* eslint-disable-next-line  */}
                                                    <ListItem selected={answers[question.id] == index + 1 && c !== "correct"} classes={(question.answer == index + 1) && (c === "correct") ? classes : null}>
                                                        <ListItemIcon>
                                                            <RadioButtonUnchecked />
                                                        </ListItemIcon>
                                                        <ListItemText primary={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value} />
                                                        {/* eslint-disable-next-line  */}
                                                        {question.answer == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <CheckCircleOutline color="secondary" />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null
                                                        }
                                                        {/* eslint-disable-next-line  */}
                                                        {c === "incorrect" && answers[question.id] == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <Cancel />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null}
                                                    </ListItem>
                                                </Box>
                                                :
                                                <Box maxWidth="50%">
                                                    {/* eslint-disable-next-line  */}
                                                    <ListItem selected={answers[question.id] == index + 1 && c !== "correct"} classes={(question.answer == index + 1) && (c === "correct") ? classes : null}>
                                                        <ListItemIcon>
                                                            <RadioButtonUnchecked />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Grid container direction="row" alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <div>{alphabets[index]}</div>
                                                                </Grid>
                                                                <Grid item>
                                                                    <img src={window.location.origin + "/" + option.value} alt={`Option${index + 1}`} />
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemText>
                                                        {/* eslint-disable-next-line  */}
                                                        {question.answer == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <CheckCircleOutline color="secondary" />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null
                                                        }
                                                        {/* eslint-disable-next-line  */}
                                                        {c === "incorrect" && answers[question.id] == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <Cancel />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null}
                                                    </ListItem>
                                                </Box>

                                        ))}
                                    </List>
                                    :
                                    question.mcq === 2 ?
                                        <div style={{ border: "1px dashed black", width: "25%", padding: 8 }} key={`M${question.id}`}>
                                            <a href={IMAGE_URL + answers[question.id]} download>File</a>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ flexDirection: "row", width: "50%" }} key={question.id}>
                                                <div style={{ width: "30%", display: "inline-block" }}>
                                                    <MathfieldComponent
                                                        latex={answers[question.id]}
                                                        mathfieldConfig={{
                                                            readOnly: true,
                                                            editingOptions: {
                                                                ignoreSpacebarInMathMode: true,
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {/* eslint-disable-next-line  */}
                                                {question.answer == answers[question.id] && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                                {/* eslint-disable-next-line  */}
                                                {(c == "incorrect" && answers[question.id] == answers[question.id]) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                            </div>
                                            {/* eslint-disable-next-line  */}
                                            {(c == "incorrect" || c == "noanswer" || question.mcq != 2) ?
                                                <div style={{ flexDirection: "row", width: "50%" }} key={question.id}>
                                                    <strong>Correct answer:</strong>
                                                    <div style={{ width: "30%" }}>
                                                        <MathfieldComponent
                                                            latex={question.answer}
                                                            mathfieldConfig={{
                                                                readOnly: true,
                                                                editingOptions: {
                                                                    ignoreSpacebarInMathMode: true,
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box display={'flex'} justifyContent='space-between' marginTop={2}>
                        {questionNumber > 1 ?
                            <Button variant="contained" startIcon={<ChevronLeftRounded />} color="primary" href={`#${questionNumber - 1}`}>
                                Previous
                            </Button>
                            :
                            <div></div>}
                        <Button variant="contained" endIcon={<ChevronRightRounded />} color="primary" href={`#${questionNumber + 1}`}>
                            Next
                        </Button>
                    </Box>
                </Grid>

                :
                questions.length > 0 ?
                    <Grid item xs={8}>
                        <Card raised>
                            <CardHeader title={"Congratulations!"} />
                            <CardContent>
                                <p>You have reached the end of this section.</p>
                                <p>You can review the questions using the navigation menu on the left side of the screen</p>
                                <p>Click Complete Button below to close this section</p>
                                <Button variant="contained" color="primary" onClick={() => history.replace("/")}>Complete</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    : null
            }
        </Grid>
    )
}
export default SubmissionViewer