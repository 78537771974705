import { grey, indigo } from '@material-ui/core/colors'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Login from './app/Login'
import Main from './app/Main'
import Register from './app/Register'
import Submission from './app/Submission'
import Test from './app/Test'
import Notifier from './utils/Notifier'
function App() {
  const theme = createMuiTheme({
    palette: {
      primary: indigo,
      secondary:grey,
    },
  })
  const auth = useSelector(state => state.auth)
  const AuthorizedRoute = ({ component: Component, ...routeProps }) =>
    (
      <Route {...routeProps} render={(props) => auth.token ? (<Component {...props} />) : (<Redirect to="/" />)} />
    );
  return (
    <ThemeProvider theme={theme}>
      <Notifier />
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/register" component={Register} />
          <AuthorizedRoute path="/test/:id" component={Test} />
          <AuthorizedRoute path="/submission/:id" component={Submission} />
          <AuthorizedRoute path="/" component={Main} />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export default App


