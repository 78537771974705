import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getSubmissionData = createAsyncThunk(
    'submission/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get(`/test/${data.test_id}`)
        return response.data
    }
)
export const resetSubmission = createAsyncThunk(
    'submission/reset',
    async (data,thunkAPI)=>{
        const response = await makeRequest(data.token,null,thunkAPI).post('/reset',data.body)
        return response.data
    }
)
const submissionSlice = createSlice({
    name: "submission",
    initialState: {
        loading: false,
        test_data: null,
        user_data: null,
        topic_data:null,
    },
    reducers: {
        reset:(state,action)=>{
            state.test_data = null
            state.user_data = null
            state.topic_data = null
        }
    },
    extraReducers: {
        [getSubmissionData.fulfilled]: (state, action) => {
            state.loading = false
            state.test_data = action.payload.data.test_data[0]
            state.user_data = action.payload.data.user_data[0]
            state.topic_data = action.payload.data.topic_data
        },
        [getSubmissionData.rejected]: (state, action) => {
            state.loading = false
        },
        [getSubmissionData.pending]: (state, action) => {
            state.loading = true
        },
        [resetSubmission.fulfilled]: (state, action) => {
            state.loading = false
        },
        [resetSubmission.rejected]: (state, action) => {
            state.loading = false
        },
        [resetSubmission.pending]: (state, action) => {
            state.loading = true
        },
    }
})
export const {reset} = submissionSlice.actions
export default submissionSlice.reducer