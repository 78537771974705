import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import notifyReducer from './Redux/notifySlice';
import authReducer from './Redux/authSlice';
import dashboardReducer from './Redux/dashboardSlice'
import subjectReducer from './Redux/subjectSlice'
import testReducer from './Redux/testSlice'
import submissionReducer from './Redux/submissionSlice'
import reportReducer from './Redux/reportSlice'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist:['auth']
}
const rootReducer = combineReducers({
  auth: authReducer,
  notify:notifyReducer,
  dashboard:dashboardReducer,
  subject:subjectReducer,
  test:testReducer,
  submission:submissionReducer,
  report:reportReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});
export const persistor = persistStore(store)
export default store;