import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getTestData = createAsyncThunk(
    'test/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get(`/test/${data.test_id}`)
        return response.data
    }
)
export const submitTest = createAsyncThunk(
    'test/submitTest',
    async (data, thunkApi) => {
        const response = await makeRequest(data.token, 'multipart/form-data', thunkApi).post('/test', data.body)
        return response.data
    }
)
const testSlice = createSlice({
    name: "test",
    initialState: {
        loading: false,
        test_data: null,
        submitted: false,
        topic_data: null,
    },
    reducers: {
        reset:(state,action)=>{
            state.test_data = null
            state.topic_data = null
            state.submitted = false
        }
    },
    extraReducers: {
        [getTestData.fulfilled]: (state, action) => {
            state.loading = false
            state.test_data = action.payload.data.test_data[0]
            state.topic_data = action.payload.data.topic_data
        },
        [getTestData.rejected]: (state, action) => {
            state.loading = false
        },
        [getTestData.pending]: (state, action) => {
            state.loading = true
        },
        [submitTest.fulfilled]: (state, action) => {
            state.loading = false
            state.submitted = true
        },
        [submitTest.rejected]: (state, action) => {
            state.loading = false
        },
        [submitTest.pending]: (state, action) => {
            state.loading = true
        },
    }
})
export const {reset} = testSlice.actions
export default testSlice.reducer