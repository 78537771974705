import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';

export default function Timeup({ open, setOpen, onOk, moreTime }) {
    return (
        <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Time is up!"}</DialogTitle>
            <DialogContent>
                {moreTime ?
                    <DialogContentText>
                        {"Since the assignment does not have a time limit you can continue"}
                    </DialogContentText>
                    :
                    <DialogContentText>
                        {"The time is up and the assignment is submitted."}
                    </DialogContentText>}
            </DialogContent>
            <DialogActions>
                {moreTime ?
                    <Button onClick={() => setOpen(false)} color="primary">Continue</Button>
                    :
                    <Button onClick={onOk} color="primary">OK</Button>
                }
            </DialogActions>
        </Dialog>
    );
}
