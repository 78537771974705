const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const { default: makeRequest } = require("../../utils/makeRequest");
export const sendReport = createAsyncThunk(
    'report/sendReport',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token,'multipart/form-data', thunkAPI).post('/bugReport', data.body)
        return response.data
    }
)
const reportSlice = createSlice({
    name: "report",
    initialState: {
        loading: false
    },
    extraReducers: {
        [sendReport.fulfilled]: (state, action) => {
            state.loading = false
        },
        [sendReport.pending]: (state, action) => {
            state.loading = true
        },
        [sendReport.rejected]: (state, action) => {
            state.loading = false
        }
    }
})
export default reportSlice.reducer