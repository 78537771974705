import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import React, { useRef, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { login } from './Redux/authSlice'
const useStyles = makeStyles({
    main: {
        flexGrow: 1,
        backgroundImage: `url(${require('../assets/login-bg.webp')})`,
        height: "100vh",
        width: "100vw"
    },
    side: {
        width: 340
    },
    root: {
        padding: 8
    }
})
function Login() {
    const classes = useStyles()
    const username = useRef()
    const password = useRef()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const history = useHistory();
    useEffect(() => {
        if (auth.token) {
            history.push("/dashboard");
        }
        // eslint-disable-next-line
    }, [auth.token])
    const doLogin = () => {
        let data = {
            username: username.current.value,
            password: password.current.value
        }
        dispatch(login(data))
    }
    return (
        <Grid container direction="row" className={classes.main} justify="center" alignContent="center">
            <Grid item className={classes.side}>
                <Card className={classes.root} raised>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography color="textPrimary" gutterBottom align="center" variant="h5">Select School Melbourne</Typography>
                            </Grid>
                            <Grid item>
                                <TextField label="Username" fullWidth variant="outlined" size="small" inputRef={username} />
                            </Grid>
                            <Grid item>
                                <TextField label="Password" fullWidth variant="outlined" type="password" size="small" inputRef={password} />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" fullWidth onClick={doLogin}>Sign In</Button>
                            </Grid>
                            {/* <Grid item>
                                <Button  color="primary" fullWidth>Forgot Password?</Button>
                            </Grid> */}
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" fullWidth onClick={() => { history.push("/register") }}>Sign Up</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Login
