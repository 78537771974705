import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTestData, reset, submitTest } from './Redux/testSlice'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import LiveTv from '@material-ui/icons/LiveTvRounded'
import PersistentDialog from './Components/PersistentDialog';
import TestDurationPicker from './Components/TestDurationPicker';
import Countdown from 'react-countdown'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import ConfirmationAlert from './Components/ConfirmationAlert'
import { useHistory } from 'react-router-dom'
import Timeup from './Components/Timeup'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { IconButton } from '@material-ui/core'
import { ChevronLeftRounded } from '@material-ui/icons'
import QuestionViewer from './Components/QuestionViewer'
const useStyles = makeStyles({
    title: {
        fontFamily: "Lora, serif",
        textTransform: "uppercase",
    }
})
function Test(props) {
    const classes = useStyles();
    const auth = useSelector(state => state.auth)
    const test = useSelector(state => state.test)
    const [questions, setQuestions] = useState([])
    const [data, setData] = useState({})
    const [open, setOpen] = useState(true)
    const [time, setTime] = useState()
    const [durationPicker, setDurationPicker] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [timeup, setTimeup] = useState(false)
    const [moreTime, setMoreTime] = useState(false)
    const history = useHistory()
    const [file, setFile] = useState()
    let tick = null;
    const handleChange = (event) => {
        let newData = Object.assign({}, data)
        newData[event.target.name] = event.target.value
        setData(newData)
    }
    const handleMathChange = (question, value) => {
        let newData = data
        newData[question] = value
        setData(newData)
    }
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getTestData({
            token: auth.token,
            test_id: props.match.params.id
        }))
        // eslint-disable-next-line
    }, [props.match.params.id])
    useEffect(() => {
        if (test.test_data) {
            setQuestions(test.test_data.questions)
        }
        // eslint-disable-next-line
    }, [test.test_data])
    const setTimer = (time) => {
        setOpen(false)
        let ttc = time * 60 * 1000
        setTime(ttc)
    }
    const onTimeUp = () => {
        setConfirm(false)
        if (test.test_data.time_limit) {
            setTimeup(true)
        } else {
            setMoreTime(true)
            setTimeup(true)
        }
    }
    const sendData = () => {
        setConfirm(false)
        let t = parseInt(time) / (60 * 1000)
        let time_taken = 0
        if (tick) {
            time_taken = parseInt(tick.hours) * 60 + parseInt(tick.minutes)
        }
        let formData = new FormData()
        formData.append("data", JSON.stringify(data))
        if (tick) {
            formData.append("time_taken", t - time_taken)
        }
        formData.append("test_id", props.match.params.id)
        if (file) {
            formData.append("question_id", file.question_id)
            formData.append("upload", file.file)
        }
        dispatch(submitTest({
            token: auth.token,
            body: formData
        }))
    }
    useEffect(() => {
        if (test.submitted) {
            dispatch(reset())
            history.replace('/')
        }
        // eslint-disable-next-line 
    }, [test.submitted])
    return (
        <div>
            <PersistentDialog open={open} setOpen={setOpen} loading={test.loading} setTimer={setTimer} />
            <TestDurationPicker open={durationPicker} setOpen={setDurationPicker} onSet={setTimer} />
            <ConfirmationAlert open={confirm} setOpen={setConfirm} onYes={() => { sendData() }} />
            <Timeup open={timeup} setOpen={setTimeup} onOk={() => sendData()} moreTime={moreTime} />
            <AppBar position="fixed">
                <Toolbar>

                    <img src='/appbar_logo.png' alt='logo' style={{ height: 64, width: 64 }} />
                    <Typography noWrap className={classes.title} align="center">Select School Melbourne</Typography>
                    <div style={{ flexGrow: 1, alignItems: "center", textAlign: "right" }}>
                        {time ?
                            <Countdown date={Date.now() + time} onComplete={onTimeUp} onTick={(data) => tick = data} /> :
                            <Button color="inherit" onClick={() => setDurationPicker(true)}>Set Timer</Button>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <div style={{ padding: 72, backgroundColor: "#fff" }}>
                <Grid container item alignItems="center" direction="column" style={{ marginBottom: 16 }}>
                    <Grid item>
                        <Typography variant={"h5"}>{test.topic_data?.name}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"h4"}>{test.test_data?.name}</Typography>
                    </Grid>
                    {test.test_data?.tutorial_link ?
                        <Grid item>
                            <Button
                                href={test.test_data.tutorial_link}
                                target="_blank"
                                variant="contained"
                                color="primary"
                                startIcon={<LiveTv />}>
                                Watch Tutorial</Button>
                        </Grid>
                        :
                        null
                    }
                </Grid>

                {questions.length !== 0 ?
                    <>
                        <QuestionViewer questions={questions} data={data} handleChange={handleChange} handleMathChange={handleMathChange} setFile={setFile} submit={sendData} />
                        {/* <Grid container item justify="center">
                            <Button variant="contained" color="primary" onClick={() => {
                                let check = window.confirm("Are you sure you want to submit the test!")
                                if (check) {
                                    sendData()
                                }
                            }}>Submit</Button>
                        </Grid> */}
                    </>
                    :
                    null
                }

            </div>
        </div>
    )
}

export default Test
