import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubjectData } from './Redux/subjectSlice'
import AssignmentIcon from '@material-ui/icons/AssignmentRounded'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PlayCircle from '@material-ui/icons/PlayCircleFilledRounded';
import CheckCircle from '@material-ui/icons/CheckCircleRounded';
import Ballot from '@material-ui/icons/BallotRounded';
import DoneAll from '@material-ui/icons/DoneAll';
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Notification from '@material-ui/icons/NotificationsActiveRounded'
import Icon from '@material-ui/core/Icon'
import { useHistory } from 'react-router-dom'
import { AppBar, Hidden, ListItemAvatar, ListItemSecondaryAction, Toolbar } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ChevronRightRounded'

  
function Subject(props) {
    const [topic, setTopic] = React.useState()
    const auth = useSelector(state => state.auth)
    const subject = useSelector(state => state.subject)
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        getData()
        setTopic()
        // eslint-disable-next-line
    }, [props.match.params.id])
    const getData = () => {
        let subject_id = props.match.params.id
        if (!subject.data.subject_id) {
            dispatch(getSubjectData({
                token: auth.token,
                body: {
                    subject_id: subject_id
                }
            }))
        }
    }
    useEffect(() => {
        if (history.location.hash) {
            setTopic(subject.data[props.match.params.id]?.filter(x => x.id === parseInt(history.location.hash.replace("#", "")))[0])
        }
        // eslint-disable-next-line
    }, [subject])
    return (
        <>
            <Hidden smDown>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <AppBar position="sticky">
                            <Toolbar>
                                <Typography variant="h6" align="center">
                                    TOPICS
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Paper style={{ flex: 1, padding: 8, height: "80vh", overflowY: "auto" }}>
                            {subject.loading ?
                                <Grid item xs={12}>
                                    <LinearProgress />
                                </Grid>
                                :
                                null
                            }

                            {subject.data[props.match.params.id]?.length === 0 ?
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <Typography variant="body2">No tests available</Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                                :
                                null
                            }
                            <List>
                                {subject.data[props.match.params.id]?.map(item => (
                                    <ListItem
                                        button={true}
                                        key={item.id}
                                        onClick={() => setTopic(item)} 
                                        selected={topic?.id === item.id ? true : false} 
                                        component="a" 
                                        href={`#${item.id}`} 
                                        >
                                        <ListItemAvatar><AssignmentIcon /></ListItemAvatar>
                                        <ListItemText primary={item.name} primaryTypographyProps={{ variant: "button" }} />
                                        <ListItemSecondaryAction>
                                            <ChevronRight />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs>

                        {topic ?
                            <>
                                <AppBar position="sticky">
                                    <Toolbar>
                                        <Typography variant="h6">
                                            ASSIGNMENTS
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <Paper style={{ flex: 1, padding: 8, height: "80vh", overflowY: "auto" }}>

                                    <List>
                                        {topic.tests.map(test => {
                                            if (test.final_test === 1) return null
                                            return (
                                                <ListItem button component="a" key={test.id} onClick={() => {
                                                    if (test.submission) {
                                                        window.open(`/submission/${test.id}`,'_blank')
                                                    } else {
                                                        window.open(`/test/${test.id}`,'_blank')
                                                    }
                                                }}>
                                                    <ListItemIcon>
                                                        {test.submission ? <CheckCircle color={"secondary"} /> : <PlayCircle />}
                                                    </ListItemIcon>
                                                    <ListItemText primary={test.name} />
                                                    {(test.submission && JSON.parse(test.submission.test_data).checked) ?
                                                        <ListItemSecondaryAction>
                                                            <Icon>
                                                                <Notification color={"secondary"} />
                                                            </Icon>
                                                        </ListItemSecondaryAction>
                                                        :
                                                        null}
                                                </ListItem>
                                            )
                                        })}
                                        {topic.tests.map(test => {
                                            if (test.final_test === 1) {
                                                return (
                                                    <>
                                                        <Divider />
                                                        <ListItem button component="a" key={test.id} onClick={() => {
                                                            if (test.submission) {
                                                                window.open(`/submission/${test.id}`,'_blank')
                                                            } else {
                                                                window.open(`/test/${test.id}`,'_blank')
                                                            }
                                                        }}>
                                                            <ListItemIcon>
                                                                {test.submission ? <DoneAll color={"secondary"} /> : <Ballot />}
                                                            </ListItemIcon>
                                                            <ListItemText primary={test.name} secondary={test.submission ? `${test.submission.score}/${test.submission.total_questions}` : null} />
                                                            {(test.submission && JSON.parse(test.submission.test_data).checked) ?
                                                                <ListItemSecondaryAction>
                                                                    <Icon>
                                                                        <Notification color={"secondary"} />
                                                                    </Icon>
                                                                </ListItemSecondaryAction>
                                                                :
                                                                null}
                                                        </ListItem>
                                                    </>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </List>
                                </Paper>
                            </>
                            :
                            null
                        }
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid container spacing={2}>
                    {subject.loading ?
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                        :
                        null
                    }
                    {subject.data[props.match.params.id]?.length === 0 ?
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body2">No tests available</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        :
                        null
                    }
                    {subject.data[props.match.params.id]?.map(item => (
                        <Grid item key={item.id} xs={12} sm={6}>
                            <Card>
                                <CardHeader title={item.name} titleTypographyProps={{ variant: "button" }} avatar={<AssignmentIcon />} />
                                <List>
                                    {item.tests.map(test => {
                                        if (test.final_test === 1) return null
                                        return (
                                            <ListItem button component="a" key={test.id} onClick={() => {
                                                if (test.submission) {
                                                    history.push(`/submission/${test.id}`)
                                                } else {
                                                    history.push(`/test/${test.id}`)
                                                }
                                            }}>
                                                <ListItemIcon>
                                                    {test.submission ? <CheckCircle color={"secondary"} /> : <PlayCircle />}
                                                </ListItemIcon>
                                                <ListItemText primary={test.name} />
                                                {(test.submission && JSON.parse(test.submission.test_data).checked) ?
                                                    <ListItemSecondaryAction>
                                                        <Icon>
                                                            <Notification color={"secondary"} />
                                                        </Icon>
                                                    </ListItemSecondaryAction>
                                                    :
                                                    null}
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                {item.tests.map(test => {
                                    if (test.final_test === 1) {
                                        return (
                                            <>
                                                <Divider />
                                                <ListItem button component="a" key={test.id} onClick={() => {
                                                    if (test.submission) {
                                                        history.push(`/submission/${test.id}`)
                                                    } else {
                                                        history.push(`/test/${test.id}`)
                                                    }
                                                }}>
                                                    <ListItemIcon>
                                                        {test.submission ? <DoneAll color={"secondary"} /> : <Ballot />}
                                                    </ListItemIcon>
                                                    <ListItemText primary={test.name} secondary={test.submission ? `${test.submission.score}/${test.submission.total_questions}` : null} />
                                                    {(test.submission && JSON.parse(test.submission.test_data).checked) ?
                                                        <ListItemSecondaryAction>
                                                            <Icon>
                                                                <Notification color={"secondary"} />
                                                            </Icon>
                                                        </ListItemSecondaryAction>
                                                        :
                                                        null}
                                                </ListItem>
                                            </>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Hidden>
        </>
    )
}

export default Subject
