import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default function TestDurationPicker({ open, setOpen, onSet }) {
    const [hours, setHours] = useState(0)
    const [mins, setMins] = useState(0)
    const handleClose = () => {
        let final_time = 0;
        final_time = hours * 60
        final_time = parseInt(final_time) + parseInt(mins)
        onSet(final_time)
        setOpen(false)
    };
    return (
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Select target duration"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item>
                        <TextField label="Hours" type="number" onChange={(event) => setHours(event.target.value)} />
                    </Grid>
                    <Grid item>
                        <TextField label="Minutes" type="number" onChange={(event) => setMins(event.target.value)} />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">*Once set button is clicked the timer will start</Typography>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">SET</Button>
            </DialogActions>
        </Dialog>
    );
}
