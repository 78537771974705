import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import makeRequest from '../../utils/makeRequest';
export const login = createAsyncThunk(
  'auth/login',
  async (data, thunkAPI) => {
    const response = await makeRequest(null, null, thunkAPI).post('/login', data)
    return response.data
  }
)
export const updateUser = createAsyncThunk(
  'user/updateUser',
  async(data,thunkAPI)=>{
    const response = await makeRequest(data.token,data.contentType,thunkAPI).post('/updateUser',data.body)
    return response.data
  }
)
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    loading: false,
    subjects:[]
  },
  reducers: {
    logout: state => {
      state.token = null
      state.user = null
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.loading = false
      state.user = action.payload.data.user
      state.token = action.payload.data.token
      state.subjects = action.payload.data.subjects
    },
    [login.pending]: (state, action) => {
      state.loading = true
    },
    [login.rejected]: (state, action) => {
      state.loading = false
    },
    [updateUser.fulfilled]:(state,action)=>{
      state.loading = false
      state.user = action.payload.data
    },
    [updateUser.pending]:(state,action)=>{
      state.loading = true
    },
    [updateUser.rejected]:(state,action)=>{
      state.loading = true
    },
  }
});
export const { logout } = authSlice.actions;
export default authSlice.reducer;
