import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationAlert({ open, setOpen, onYes }) {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogActions>
                <Button onClick={()=>setOpen(false)} color="primary">No</Button>
                <Button onClick={onYes} color="primary">Yes</Button>
            </DialogActions>
        </Dialog>
    );
}
