import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import Button from '@material-ui/core/Button'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TabPanel from './Components/TabPanel'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import Axios from 'axios'
import LinearProgress from '@material-ui/core/LinearProgress'
import { SERVER_URL } from '../utils/constants'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { enqueue } from './Redux/notifySlice'
const useStyles = makeStyles({
    main: {
        flexGrow: 1,
        backgroundImage: `url(${require('../assets/login-bg.webp')})`,
        height: "100vh",
        width: "100vw"
    },
    side: {
        width: 340
    },
    root: {
        padding: 8
    }
})
function Register() {
    const [page, setPage] = useState(0)
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const dispatch = useDispatch()
    const history = useHistory()
    const [errors, setErrors] = useState({
        name: {
            error: false,
            msg: ""
        },
        grade: {
            error: false,
            msg: ""
        },
        school: {
            error: false,
            msg: ""
        },
        birthdate: {
            error: false,
            msg: ""
        },
        mobile_number: {
            error: false,
            msg: ""
        },
        email: {
            error: false,
            msg: ""
        },
        address: {
            error: false,
            msg: ""
        },
        username: {
            error: false,
            msg: ""
        },
        password: {
            error: false,
            msg: ""
        },
        password_confirmation: {
            error: false,
            msg: ""
        }
    })
    const handleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }
    const setError = (name, msg) => {
        let newErrors = {}
        for (const field in errors) {
            newErrors[field] = {
                error: false,
                msg: ""
            }
        }
        let newObj = {
            error: true,
            msg: msg
        }
        setErrors({ ...newErrors, [name]: newObj })
    }
    const nextPage = () => {
        if (page === 0) {
            if (!data.name || data.name.trim() === "") {
                setError("name", "Please enter your full name")
                return
            }
            if (!data.grade || data.grade.trim() === "") {
                setError("grade", "Please enter your grade")
                return
            }
            if (!data.school || data.school.trim() === "") {
                setError("school", "Please enter your school name")
                return
            }
            if (!data.birthdate || data.birthdate.trim() === "") {
                setError("birthdate", "Please enter your birthdate")
                return
            }
            if (!data.mobile_number || data.mobile_number.trim() === "") {
                setError("mobile_number", "Please enter your mobile number")
                return
            }
            if (!data.email || data.email.trim() === "") {
                setError("email", "Please enter email address")
                return
            }
            if (!/^(([^<>()[\],;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email)) {
                setError("email", "Invalid email address")
                return
            }
            if (!data.address || data.address.trim() === "") {
                setError("address", "Please enter your address")
                return
            }
        }
        setPage(page + 1)
    }
    const register = () => {
        if (!data.username || data.username.trim() === "") {
            setError("username", "Please enter username")
            return
        }
        if (!data.password || data.password.trim() === "") {
            setError("password", "Please enter password")
            return
        }
        if (data.password !== data.password_confirmation) {
            setError("password_confirmation", "Confirm password does not match")
            return
        }
        setLoading(true)
        Axios.post(SERVER_URL + "/register", data).then(res => {
            setLoading(false)
            if (res.data.status === 1) {
                dispatch(enqueue(
                    {
                        message: res.data.msg,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                        },
                    }
                ))
                history.replace("/login")
            } else if (res.data.status === 0) {
                for (const key in res.data.data) {
                    setError(key, res.data.data[key][0])
                    if (key === "mobile_number") {
                        setPage(0)
                    }
                    return
                }
            }
        }).catch(err=>{
            setLoading(false)
            dispatch(enqueue(
                {
                    message: "Please check your internet connection and try again",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                }
            ))
        })
    }
    return (
        <Grid container direction="column" className={classes.main} justify="center" alignContent="center">
            <Grid item className={classes.side}>
                <Card className={classes.root} raised>
                    {loading ? <LinearProgress /> : null}
                    <CardHeader title="Register" titleTypographyProps={{ variant: "h5" }} subheader={<Typography variant="subtitle2">Fields marked with * are required</Typography>} />
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TabPanel value={page} index={0}>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">Student Informarion</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Full Name" onChange={handleChange} size="small" variant="outlined" name="name" value={data["name"]} helperText={errors.name.msg} error={errors.name.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Grade" onChange={handleChange} size="small" variant="outlined" type="number" name="grade" value={data["grade"]} helperText={errors.grade.msg} error={errors.grade.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="School" onChange={handleChange} size="small" variant="outlined" name="school" value={data["school"]} helperText={errors.school.msg} error={errors.school.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Birth Date" onChange={handleChange} size="small" variant="outlined" name="birthdate" type="date" InputLabelProps={{ shrink: true }} value={data["date"]} helperText={errors.birthdate.msg} error={errors.birthdate.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Mobile Number" onChange={handleChange} size="small" variant="outlined" name="mobile_number" type="number" value={data["mobile_number"]} helperText={errors.mobile_number.msg} error={errors.mobile_number.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Email Address" onChange={handleChange} size="small" variant="outlined" name="email" value={data["email"]} helperText={errors.email.msg} error={errors.email.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Address" onChange={handleChange} size="small" variant="outlined" name="address" multiline rowsMax={4} value={data["address"]} helperText={errors.address.msg} error={errors.address.error} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={page} index={1}>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">Parent/Carer Informarion</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="small">Parent/Carer 1</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField fullWidth label="Full Name" onChange={handleChange} size="small" variant="outlined" name="parent1Name" value={data["parent1Name"]} />
                                        </Grid>
                                        <Grid item>
                                            <TextField fullWidth label="Mobile Number" onChange={handleChange} size="small" variant="outlined" name="parent1Number" type="number" value={data["parent1Number"]} />
                                        </Grid>
                                        <Grid item>
                                            <TextField fullWidth label="Email Address" onChange={handleChange} size="small" variant="outlined" name="parent1Email" type="email" value={data["parent1Email"]} />
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="small">Parent/Carer 2</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField fullWidth label="Full Name" onChange={handleChange} size="small" variant="outlined" name="parent2Name" />
                                        </Grid>
                                        <Grid item>
                                            <TextField fullWidth label="Mobile Number" onChange={handleChange} size="small" variant="outlined" name="parent2Number" type="number" />
                                        </Grid>
                                        <Grid item>
                                            <TextField fullWidth label="Email Address" onChange={handleChange} size="small" variant="outlined" name="parent2Email" type="email" />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={page} index={2}>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">Login Informarion</Typography>
                                        </Grid>

                                        <Grid item>
                                            <TextField required fullWidth label="Username" onChange={handleChange} size="small" variant="outlined" name="username" helperText={errors.username.msg} error={errors.username.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Password" onChange={handleChange} size="small" variant="outlined" name="password" type="password" helperText={errors.password.msg} error={errors.password.error} />
                                        </Grid>
                                        <Grid item>
                                            <TextField required fullWidth label="Confirm Password" onChange={handleChange} size="small" variant="outlined" name="password_confirmation" type="password" helperText={errors.password_confirmation.msg} error={errors.password_confirmation.error} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                            {page === 3 ? null :
                                <Grid container item spacing={2}>
                                    {page !== 0 ?
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={() => { setPage(page - 1) }} disabled={loading}>Previous</Button>
                                        </Grid>
                                        :
                                        null
                                    }
                                    {page !== 2 ?
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={nextPage} disabled={loading}>Next</Button>
                                        </Grid>
                                        :
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={register} disabled={loading}>Register</Button>
                                        </Grid>
                                    }

                                </Grid>}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Register
