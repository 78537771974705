import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubmissionData, reset, resetSubmission } from './Redux/submissionSlice'
import RotateLeft from '@material-ui/icons/RotateLeft';
import LiveTv from '@material-ui/icons/LiveTvRounded'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/styles/makeStyles'
import { useHistory } from 'react-router'
import SubmissionViewer from './Components/SubmissionViewer'
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { ChevronLeftRounded } from '@material-ui/icons';
const useStyles = makeStyles({
    title: {
        fontFamily: "Lora, serif",
        textTransform: "uppercase",
    }
})
function Submission(props) {
    const classes = useStyles();

    const auth = useSelector(state => state.auth)
    const submission = useSelector(state => state.submission)
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [score, setScore] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getSubmissionData({
            token: auth.token,
            test_id: props.match.params.id
        }))
        // eslint-disable-next-line
    }, [props.match.params.id])
    useEffect(() => {
        return () => {
            dispatch(reset())
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (submission.test_data && submission.user_data) {
            setQuestions(submission.test_data.questions)
            setAnswers(JSON.parse(submission.user_data.test_data))
            setScore(`${submission.user_data.score}/${submission.user_data.total_questions}`)
        }
        // eslint-disable-next-line 
    }, [submission.test_data])
    return (
        <div>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton onClick={() => history.replace("/")}><ChevronLeftRounded style={{ color: 'white' }} /></IconButton>
                    <img src='/appbar_logo.png' alt='logo' style={{height:64,width:64}}/>
                    <Typography noWrap className={classes.title} align="center">Select School Melbourne</Typography>

                </Toolbar>
            </AppBar>
            <div style={{ padding: 72 }}>
                <Grid container spacing={2} direction="column">
                    <Grid container item alignItems="center" direction="column">
                        {submission.user_data ?
                            <>
                                <Grid item>
                                    <Typography variant={"h5"}>{submission.topic_data?.name} </Typography>  
                                </Grid>
                                <Grid item>
                                    <Typography variant={"h4"}>{submission.test_data?.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={async () => {
                                            if (window.confirm(`Are you sure? \nThis will reset your test data`)) {
                                                let formData = new FormData()
                                                formData.append("id", submission.user_data.id)
                                                let data = {
                                                    token: auth.token,
                                                    body: formData
                                                }
                                                let r = await dispatch(resetSubmission(data))
                                                if (r.payload) {
                                                    history.goBack()
                                                }
                                            }
                                        }}
                                        startIcon={<RotateLeft />}
                                        variant="contained"
                                        color="primary"
                                    >Reset</Button>
                                </Grid></>
                            :
                            null
                        }
                        {submission.test_data?.tutorial_link ?
                            <Grid item>
                                <Button
                                    href={submission.test_data.tutorial_link}
                                    target="_blank"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<LiveTv />}>
                                    Watch Tutorial</Button>
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                    <SubmissionViewer questions={questions} answers={answers} score={score} />
                </Grid>
            </div>
        </div>
    )
}

export default Submission
