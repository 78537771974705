import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getSubjectData = createAsyncThunk(
    'subject/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post(`/subject`,data.body)
        return {
            subject_id: data.body.subject_id,
            response: response.data
        }
    }
)
const subjectSlice = createSlice({
    name: "subject",
    initialState: {
        loading: false,
        data: []
    },
    reducers: {

    },
    extraReducers: {
        [getSubjectData.fulfilled]: (state, action) => {
            state.loading = false
            state.data = {...state.data, [action.payload.subject_id]: action.payload.response.data}
        },
        [getSubjectData.pending]: (state, action) => {
            state.loading = true
        },
        [getSubjectData.rejected]: (state, action) => {
            state.loading = false
        }
    }
})
export default subjectSlice.reducer