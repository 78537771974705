import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardData } from './Redux/dashboardSlice'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
function Dashboard() {
    const dashboard = useSelector(state => state.dashboard)
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const history = useHistory()
    useEffect(() => {
        dispatch(getDashboardData({
            token: auth.token
        }))
        // eslint-disable-next-line
    }, [])
    function time_convert(num) {
        var hours = Math.floor(num / 60);
        var minutes = num % 60;
        let smins = minutes === 0 ? "" : ` ${minutes} minutes`
        return `0${hours} hours` + smins;
    }
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Topic</TableCell>
                                <TableCell>Test Name</TableCell>
                                <TableCell>Total Questions</TableCell>
                                <TableCell>Marks Obtained</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>View Report</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dashboard.graphTableData.length === 0 ?
                                <TableCell colSpan={5}>No data available</TableCell>
                                :
                                dashboard.graphTableData.map((row) => {
                                    return (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.topic_name}
                                            </TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.total_questions}</TableCell>
                                            <TableCell>{row.score}</TableCell>
                                            <TableCell>{row.time_taken ? time_convert(row.time_taken) : "NA"}</TableCell>
                                            <TableCell>
                                                <Button onClick={() => history.push(`/submission/${row.test_id}`)}>
                                                    View Report
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default Dashboard
