import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getDashboardData = createAsyncThunk(
    'dashboard/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get('/')
        return response.data
    }
)
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        graphTableData: [],
        numOfSubmissions: null,
        numOfTests:null,
        loading: false
    },
    reducers: {

    },
    extraReducers: {
        [getDashboardData.fulfilled]: (state, action) => {
            state.loading = false
            state.graphTableData = action.payload.data.past_records
            state.numOfSubmissions = action.payload.data.num_of_submissions
            state.numOfTests = action.payload.data.num_of_tests
        },
        [getDashboardData.pending]: (state, action) => {
            state.loading = true
        },
        [getDashboardData.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export default dashboardSlice.reducer